export default [
  {
    key: 'diamondAmount',
    label: 'field.amount_diamond',
    type: 'currency',

    disabled: true,
    cols: 6,
  },
  {
    key: 'price',
    label: 'field.price',
    type: 'currency',
    disabled: true,

    cols: 6,
  },
  {
    key: 'isDiscount',
    label: 'status.is_discount',
    type: 'checkbox',
    disabled: true,
    cols: 12,
  },
  {
    key: 'wholeSaleName',
    label: 'field.wholeSale',
    type: 'text',
    cols: 12,
    disabled: true,
  },
  {
    key: 'receiptUrl',
    label: 'field.receipt',
    type: 'single-image',
    cols: 12,
    ph: 500,
    pw: 500,
    initKey: 'mediaFile',
    fullWidth: false,
    disabled: true,
  },
  {
    key: 'orderType',
    label: 'status.order_type',
    type: 'text',
    cols: 12,
    disabled: true,
  },
  {
    key: 'isApprove',
    label: 'field.isApprove',
    rules: 'required',
    type: 'text',
    cols: 12,
  },
  {
    key: 'rejectReason',
    label: 'field.rejectReason',
    rules: 'required_if:isApprove,rejected',
    type: 'singleSelect',
    options: [
      'សូមអភយ័ទោស លោកអ្នកបានផ្ញើរទឹកប្រាក់មិនគ្រប់គ្រាន់សម្រាប់កញ្ចប់ទិញនេះទេ',
      'សូមអភយ័ទោស លោកអ្នកផ្ញើរទឹកប្រាក់មកលេខអ្នកទទួលមិនត្រឹមត្រូវ',
    ],
    cols: 12,
  },
]
